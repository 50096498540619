import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface IServiceWorkerJSON extends IStepSyncProgressJSON {
}

export interface IServiceWorkerOptions extends IStepSyncProgressOptions {
}

export class ServiceWorkerProgress extends StepSyncProgress {
    get progressStatusPending(): number {
        throw new Error('Method not implemented.');
    }

    get progressStatusSuccess(): number {
        throw new Error('Method not implemented.');
    }

    get progressStatusError(): number {
        throw new Error('Method not implemented.');
    }

    constructor(options: IServiceWorkerOptions | IServiceWorkerJSON) {
        super(options);
    }

    public toJSON(): IServiceWorkerJSON {
        return Object.assign(super.toJSON(), this);
    }
}
